import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import cardsService from "../../services/cards.services";
import ModelPopup from "../../helpers/ModelPopup";
import { Env } from "../../enviroment/environment";
import { Link } from "react-router-dom";
import BoLoader from "../../helpers/BoLoader";
import GetAllClients from "../../helpers/GetAllClients";
import RefreshToken from "../../helpers/RefreshToken";
import { haspermissions } from "../permissions/PermissionTypes";
import SearchGridBankAccounts from "./SearchGridBankAccounts";
import AddBankAccount from "./AddBankAccounts";

const API_URL = Env.BankAccountApiUrl;
const SearchGridInputData = [
  {
    Name: "",
    TokenId: "",
    IssueDate: "",
    ExpiryDate: "",
    DateFrom: "",
    DateTo: "",
    CardNumber:"",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
    SearchClearClicked: false,
    ClientId: "",
    IssuerType: "",
  },
];
const BankAccountHolders = () => {
  const [modalShow, setModalShow] = useState(false);
  const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  const [isExcelDownload, setiisExcelDownload] = useState(false);


  const { user: currentUser } = useSelector((state) => state.auth);
  const [cardContent, setCardContent] = useState();
  const [superAdminCardsDataSuccessful, setSuperAdminCardsDataSuccessful] =
    useState(false);
  const [boloader, setBoLoader] = useState(false);
  const [allClients, setAllClients] = useState();
  const clientId = currentUser.role.includes("Client admin")
    ? currentUser.user.ClientId
    : "";
  const [AllCardsTab, setAllCardsTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [AllCardsTabAreaSelected, setAllCardsTabAreaSelected] =
    useState("false");
  const [NewCardsTab, setNewCardsTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [NewCardsTabAreaSelected, setNewCardsTabAreaSelected] =
    useState("false");
  const [SuspendedCardTab, setSuspendedCardTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [SuspendedCardTabAreaSelected, setSuspendedCardTabAreaSelected] =
    useState("false");
  const [ClosedCardTab, setClosedCardTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [ClosedCardTabAreaSelected, setClosedCardTabAreaSelected] =
    useState("false");
  const [content, setContent] = useState(<p>No Record Found</p>);
  const [NewCardContent, setNewCardContent] = useState();
  const [SuspendCardContent, setSuspendCardContent] = useState();
  //Export To Excel
  const [dataset, setDataset] = useState();
  const [IssuerCategory, setIssuerCategory] = useState("");
  const [bulkIssueCard, setBulkIssueCard] = useState(false);
  if (currentUser) {
    haspermissions.IssueCard = currentUser?.user?.Permissions?.includes("Issue Card");
    haspermissions.LoadCard = currentUser?.user?.Permissions?.includes("Load Card");
    haspermissions.ActivatePhysicalCard = currentUser?.user?.Permissions?.includes("Activate Physical Card");
    haspermissions.BulkIssueCard = currentUser?.user?.Permissions?.includes("Issue Bulk Card");
    haspermissions.ActivateBulkCard = currentUser?.user?.Permissions?.includes("Activate Bulk Card");
    haspermissions.BulkLoadCard = currentUser?.user?.Permissions?.includes("Issue Bulk Load");
  }
  useEffect(() => {
    setBoLoader(true);
    btnAllCards();

    setSuperAdminCardsDataSuccessful(false);
    cardsService.getCardStatusData(clientId).then(
      (response) => {
        setBoLoader(false);
        setCardContent(response.data.payload.result);
        setSuperAdminCardsDataSuccessful(true);
        setAllClients(<GetAllClients />);
      },
      (error) => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
            setBoLoader(false);
          } else {
            setBoLoader(false);
          }
        })();
      }
    );
  }, []);

  const btnAllCards = () => {
    setAllCardsTab("nav-link active d-flex align-items-center");
    setAllCardsTabAreaSelected("true");
    setNewCardsTab("nav-link d-flex align-items-center");
    setNewCardsTabAreaSelected("false");
    setSuspendedCardTab("nav-link d-flex align-items-center");
    setSuspendedCardTabAreaSelected("false");
    setClosedCardTab("nav-link d-flex align-items-center");
    setClosedCardTabAreaSelected("false");
    setiisExcelDownload(false);
    setContent(
      <div>
        <h3></h3>
        <SearchGridBankAccounts
          methodname="getaccountlist"
          placeholdervalue="Search Accounts"
          headersdata={[
            "ACCOUNT TITLE",
            "CLIENT NAME",
            "TOKEN ID",
            "ACCOUNT NO",
            "VIEW",
          ]}
          apiurl={API_URL}
          screenname="bankaccount"
          clientId={clientId}
          setDataset={setDataset}
          IssuerCategory={setIssuerCategory}
        />
      </div>
    );
  };
  
 
 
  const AddAccountPopupHandler = () => {
    setModalShow(true);
    setCnfrmModalShow(false);
  };

  const BulkIssueCardPopHandler = () => {
    setBulkIssueCard(true);
  };
  return (
    <div className="col-xl-9 col-md-12 bo-main-content">
      <BoLoader loading={boloader} />
     
      <div className="row mt-20">
        
       
        {
          haspermissions.IssueCard && (
            <div
              className="col-md-3 col-6 col-xl-2"
              onClick={() => setCnfrmModalShow(true)}
            >
              <div className="col-stats col-stats-action mb-40">
                <div>
                  <span className="icon-issued-card">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path7"></span>
                    <span className="path8"></span>
                    <span className="path9"></span>
                    <span className="path10"></span>
                    <span className="path11"></span>
                  </span>
                 
                  <br />
                  <Link to="#" className="mt-3">
                   Add Bank Account
                    <span className="icon-bo-arrow"></span>
                    
                  </Link>
                </div>
              </div>
            </div>
          )
        }
        
        <ModelPopup
          show={confirmmodalShow}
          onHide={() => setCnfrmModalShow(false)}
          modelheading={""}
          showheader={"false"}
          className="smallPopup issueCardSmall"
          dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
          backdrop="static"
          keyboard={false}
          modelbody={[
            <Fragment>
              <div className="row">
                <div className="col-md-12">
                  <h2>Add Bank Acount</h2>
                  <div className="mt-16">
                    <div>
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                          <div className="col-md-12">
                            <p>Do you want to Add Bank Acount?</p>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                             
                              <button
                                className="btn btn-large"
                                onClick={AddAccountPopupHandler}
                              >
                                Add Account
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>,
          ]}
        />
       
        <AddBankAccount
          show={modalShow}
          onHide={() => setModalShow(false)}
       
        />

      </div>

      <div className="bo-tabs-mbl">
        <ul
          className="nav nav-tabs nav-tabs-profile"
          id="myTab2"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className={AllCardsTab}
              id="allcards-tab"
              data-bs-toggle="tab"
              onClick={btnAllCards}
              data-bs-target="#allCards"
              type="button"
              role="tab"
              aria-controls="allcards"
              aria-selected={AllCardsTabAreaSelected}
            >
              <span className="icon-card"></span>
              Active Bank Accounts
            </button>

          </li>
          {/* <li className="nav-item" role="presentation">
            <button
              className={NewCardsTab}
              id="newcards-tab"
              data-bs-toggle="tab"
              onClick={btnPendingCards}
              data-bs-target="#newcards"
              type="button"
              role="tab"
              aria-controls="newcards"
              aria-selected={NewCardsTabAreaSelected}
            >
              <span className="icon-card-check"></span>Deleted IBANS
              <span className="bo-badge bo-badge-large">

                {cardContent?.PendingCards}</span>
            </button>
          </li> */}
         
          
        </ul>
      </div>

      <div className="">
        <div className="tab-content" id="myTabContent2">
          {AllCardsTabAreaSelected === "true" && content}
          {NewCardsTabAreaSelected === "true" && NewCardContent}
          
        </div>
      </div>

     
    </div>
  );
};

export default BankAccountHolders;
