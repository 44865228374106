import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import "../src/assets/plugins/bootstrap/css/bootstrap.min.css";
import "../src/assets/scss/screen.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min";
import Login from "./components/auth/Login";
import Dashboard from "./components/auth/Dashboard";
import CardHolders from "./components/cardholders/CardHolders";
import ClientAdmin from "./components/clilentadmin/ClientAdmin";
import SuperAdmin from "./components/superadmin/SuperAdmin";
import Navigation from "./components/navigation/nav";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import ResetPassword from "./components/account/resetpassword";
import { history } from "./helpers/history";
import ForgotPassword from "./components/account/ForgotPassword";
import AddClient from "./components/superadmin/AddClient";
import CardHolderDetails from "./components/cardholders/CardHolderDetails";
import Deposit from "./components/depositScreen/Deposit";
import Clients from "./components/superadmin/Clients";
import ClientCards from "./components/superadmin/ClientCards";
import ClientDetails from "./components/superadmin/ClientDetail";
import Settings from "./components/superadmin/Settings";
import CardHolderSettings from "./components/cardholders/Settings";
import ClientAdminSettings from "./components/clilentadmin/Settings";
import Help from "./components/Help";
import SuperAdminHelp from "./components/superadmin/SuperAdminHelp";
import DepositClients from "./components/depositscreensClients/DepositClients";
// import Transactions from "./components/superadmin/Transactions";
import CardHolderCards from "./components/cardholderCardScreens/CardHolderCards";
import CardHolderTransactions from "./components/cardholderCardScreens/CardHolderTransactions";
import MobileMenu from "./helpers/MobileMenu";
import { useIdleTimer } from "react-idle-timer";
import RoleManagment from "./components/rolemanagment/RoleManagment";
import UpdateSuperAdminUser from "./components/rolemanagment/UpdateSuperAdminUser";
import { haspermissions } from "./components/permissions/PermissionTypes";
import ChangePin from "./components/changePin/Changepin";
import CHDSendMoney from "./components/cardholderCardScreens/CHDSendMoney";
import ClientFees from "./components/clientfees/ClientFees";
import SuperClients from "./components/superclient/SuperClient";
import SuperClientDetails from "./components/superclient/SuperClientDetails";
import AccountHolders from "./components/ibanAccounts/AccountHolders";
import AccountHolderDetails from "./components/ibanAccounts/AccountHolderDetails";
import BankAccountHolders from "./components/BankAccounts/BankAccountHolders";
import BankAccountHolderDetails from "./components/BankAccounts/BankAccountHolderDetails";
const App = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
   //const [idleTime, setIdleTime] = useState(process.env.REACT_APP_LOGOUT_TIME); //// time in mili seconds for 5 mints
  const [idleTime, setIdleTime] = useState(1000000);
  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  const logOut = () => {
    dispatch(logout());
  };
  const handleOnIdle = (event) => {
		logOut();
	};
  const handleOnActive = (event) => {
    setIdleTime(idleTime);
	};

	// const handleOnAction = (event) => {
	// 	setIdleTime(idleTime);
	// };

	const {} = useIdleTimer({
		timeout: idleTime,
		onIdle: handleOnIdle,
		onActive: handleOnActive,
	//	onAction: handleOnAction,
    crossTab:true,
		debounce: 500,
	});
if(currentUser){
  haspermissions.LedgerTransfer= currentUser?.user?.Permissions?.includes("Ledger Transfer");
  haspermissions.DepositTab= currentUser?.user?.Permissions?.includes("Deposit Tab");
  haspermissions.IssueCard= currentUser?.user?.Permissions?.includes("Issue Card");
  haspermissions.LoadCard= currentUser?.user?.Permissions?.includes("Load Card");
  haspermissions.CreateClient= currentUser?.user?.Permissions?.includes("Create Client");
  haspermissions.AdminRoleManagement= currentUser?.user?.Permissions?.includes("Admin Role Management");
  haspermissions.FeeManagement= currentUser?.user?.Permissions?.includes("Fee Management");

}
// useEffect(() => {
//   window.addEventListener('unload', handleTabClosing)
//   return () => {
//       window.removeEventListener('unload', handleTabClosing)
//   }
// })
// const handleTabClosing = () => {
//   logOut()
// }

  return (
    <Router history={history}>
    
      {currentUser ? (
        <div className="bo-app-main">
          {/* header */}
          <header className="header-main-outer">
            <section className="header-main">
              <div className="row align-items-center position-relative position-md-static">
                <div className="col-12 p-static d-flex align-items-center justify-content-center">
                  <div className="div-hamburger">
                    <button className="hamburgers" type="button" onClick={() => {MobileMenu()}}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                  </div>
                  <div className="logo d-flex align-items-center bo-thumb">
                    <Link to="#">
                      <h3>
                        Super<span>Admin</span>
                      </h3>
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </header>

          <div className="row minh-row">
            <Navigation
              username={currentUser?.user?.Name}
              role={currentUser?.role}
              logOut={logOut}
              depositpermission={haspermissions.DepositTab}
              rolemanagmentpermission={haspermissions.AdminRoleManagement}
              FeeManagement={haspermissions.FeeManagement}

            />
            <Switch>
              <Route exact path={["/", "/dashboard"]} component={Dashboard} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/clientadmin" component={ClientAdmin} />
              <Route exact path="/admin" component={SuperAdmin} />
              <Route exact path="/addClient" component={AddClient} />
              <Route  exact path="/cardholderdetails" component={CardHolderDetails}/>
              <Route  exact path="/accountholderdetails" component={AccountHolderDetails}/>
              <Route  exact path="/bankaccountholderdetails" component={BankAccountHolderDetails}/>
              <Route exact path="/cards" component={CardHolders} />
              <Route exact path="/ibanaccounts" component={AccountHolders} />
              <Route exact path="/bankaccounts" component={BankAccountHolders} />
              {
                 haspermissions.DepositTab &&(
              <Route exact path="/deposit" component={Deposit} />
                 )
              }
              
               <Route exact path="/sendmoney" component={CHDSendMoney} />
              <Route exact path="/settings" component={Settings} />
              <Route exact path="/cardHolder/settings" component={CardHolderSettings}/>
              <Route  exact path="/client/settings" component={ClientAdminSettings} />
              <Route exact path="/help" component={Help} />
              <Route exact path="/admin/help" component={SuperAdminHelp} />
              
              <Route exact path="/clientcards" component={ClientCards} />
             
              <Route exact path="/clientdetails" component={ClientDetails} />
              <Route exact path="/superclientdetails" component={SuperClientDetails} />
             
              <Route exact path="/clients" component={Clients} />
              <Route exact path="/superclients" component={SuperClients} />
              {
                haspermissions.FeeManagement &&(
                  <Route exact path="/feemanagments" component={ClientFees} />
                )
              }
              {/* <Route exact path="/transactions" component={Transactions} /> */}
              <Route exact path="/depositclients" component={DepositClients} />
              <Route exact path="/cardholdercards" component={CardHolderCards}/>
              <Route exact path="/cardholdertransactions"component={CardHolderTransactions}/>
              <Route exact path="/changepins"component={ChangePin}/>
              {
                haspermissions.AdminRoleManagement &&(
                  <>
                  <Route exact path="/rolemanagment"component={RoleManagment}/>
                  <Route exact path="/updateroles"component={UpdateSuperAdminUser}/>
                  <Route exact path="/updatesuperclientroles"component={UpdateSuperAdminUser}/>

                 </>
                )
              }
             
            </Switch>
          </div>
        </div>
      ) : (
        <section className="bo-form-screens">
          <div className="container-fluid">
            <Switch>
              <Route exact path={["/", "/Login"]} component={Login} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgotpassword" component={ForgotPassword} />
              <Route exact path="/resetpassword" component={ResetPassword} />
              <Redirect to={"/login"} />

            </Switch>
          </div>
        </section>
      )}
    </Router>
  );
};

export default App;
