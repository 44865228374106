

import DialoguePopup from "../../helpers/DialoguePopup";
import React, { useState, useRef, useEffect, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import BankService from "../../services/bank.services";
import clientmanagmentObj from "../../services/client-managment.service";
import BoLoader from "../../helpers/BoLoader";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import { useSelector } from "react-redux";
import GetAllClients from "../../helpers/GetAllClients";
import RefreshToken from "../../helpers/RefreshToken";
import ReactFlagsSelect from "react-flags-select";
import { TrimCharacters } from "../../helpers/TrimCharacters";

const AddExtrnalTransferBody = props => {
    const { user: currentUser } = useSelector(state => state.auth);

    const [cardHolderDetailsAreaSelected, setCardHolderDetailsAreaSelected] =
        useState("true");
    const [cardDetailsAreaSelected, setCardDetailsAreaSelected] =
        useState("false");
    const [cardHolderDetailsClassName, setTcardHolderDetailsClassName] =
        useState("nav-link active");
    const [cardDetailsClassName, setTcardDetailsClassName] = useState("nav-link");
    const [cardHolderContentClassName, setCardHolderContentClassName] = useState(
        "tab-pane fade show active"
    );
    const [cardContentClassName, setCardContentClassName] =
        useState("tab-pane fade");
    const [modalShow, setModalShow] = React.useState(false);
    const form = useRef();
    const checkBtn = useRef();
    const [modelhdng, setMdlHdng] = useState("");
    const [MdlIcon, setMdlIcon] = useState("");
    const [modelbdy, setMdlBdy] = useState("");
    const [disableBtns, setDisabledBtns] = useState(false);
    const [boloader, setBoLoader] = useState(false);
    const [ClientNameVal, setClientNameVal] = useState("");
    const [FirstName, setFirstName] = useState("");
    const [PaymentProcessorClient, setPaymentProcessorClient] = useState("");
    const [cardFeeCurrencyCode, setcardFeeCurrencyCode] = useState();
    const [cardCurrency, setcardCurrency] = useState();
    const [showClientErr, setShowClientErr] = useState("d-none");
    const [errors, setErrors] = useState({}); // Error state

    //Issuer S or ISsuer B For m-Swipe
    const [IssuerCategory, setIssuerCategory] = useState();
    const [CardFlow, setCardFlow] = useState();
    const [CheckcardVisaMaster, setCheckCardVisaMaster] = useState("Select");
    const [formData, setFormData] = useState({
        customerId: '',
        description: '',
        counterpartyType: '', // e.g., "FIAT_US"
        supportedRails: [], // ["ACH", "FEDWIRE", "SWIFT"]

        profile: {
            profileType: '', // e.g., "INDIVIDUAL"
            name: '',
            email: '',
            telephoneNumber: '',
            taxReferenceNumber: '',
            dateOfBirth: '',
            address: {
                unitNumber: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                city: '',
                state: '',
                postalCode: '',
                countryCode: ''
            },
            lineOfBusiness: '',
            relationshipToCustomer: ''
        },

        accountInformation: {
            assetTypeId: '', // e.g., "FIAT_MAINNET_USD"
            accountNumber: '',
            type: '', // "CHECKING" / "SAVING"
            routingNumber: '',
            swiftBic: '',
            institutionName: '',
            institutionAddress: {
                unitNumber: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                city: '',
                state: '',
                postalCode: '',
                countryCode: ''
            }
        }
    });

    // Custom validation rules
    const validateField = (name, value) => {
        let errorMsg = "";

        // Ensure value is a string before using trim()
        const strValue = value !== undefined && value !== null ? String(value).trim() : "";

        if (!strValue) {
            errorMsg = `${name} is required`;
        } else {
            if (name === "email" && !/^\S+@\S+\.\S+$/.test(strValue)) {
                errorMsg = "Invalid email format";
            }
            if (name === "phone" && !/^\d{10,15}$/.test(strValue)) {
                errorMsg = "Invalid phone number (must be 10-15 digits)";
            }
        }

        return errorMsg;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Validate field
        const errorMsg = validateField(name, value);

        // Update state
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

        // Update errors
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errorMsg,
        }));
    };
    //API Call Start
    const IssueCardHolderHandler = e => {
        if (e) e.preventDefault();
        form.current.validateAll();
        let newErrors = {};
        Object.keys(formData).forEach((field) => {
            const errorMsg = validateField(field, formData[field]);
            if (errorMsg) newErrors[field] = errorMsg;
        });
        // If errors exist, update state and stop submission
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        setBoLoader(true);
        BankService.createcounterparty
            (formData,
            )
            .then(bankData => {
                setBoLoader(false);
                if (bankData && bankData.data && bankData.data.success) {
                    setCardHolderDetailsAreaSelected("false");
                    setCardDetailsAreaSelected("true");
                    setTcardHolderDetailsClassName("nav-link");
                    setTcardDetailsClassName("nav-link active");
                    setCardHolderContentClassName("tab-pane fade");
                    setCardContentClassName("tab-pane fade show active");
                }
            })
            .catch(err => {
                setBoLoader(false);
                let msgBody =
                    err && err.response && err.response.data
                        ? err.response.data.message
                        : "Error Occured While Creating Account";
                let errIcon = "icon-error";
                errorAndSuccessModl("Error", msgBody, errIcon);
                (async () => {
                    setBoLoader(true);
                    let resp = await RefreshToken(
                        err && err.response && err.response.status
                    );
                    if (resp) {
                        IssueCardHolderHandler();
                        setBoLoader(false);
                    } else {
                        setBoLoader(false);
                    }
                })();
            });


    };
    //API Call End
    const errorAndSuccessModl = (heading, body, Icon) => {
        setModalShow(true);
        setMdlHdng(heading);
        setMdlBdy(body);
        setMdlIcon(Icon);
    };

    const IssueCardDoneHandler = e => {
        e.preventDefault();
        // setDisabledBtns(true);
        props.onHide();
    };


    return (
        <Fragment>
            <div className="row">
                <BoLoader loading={boloader} />
                <div className="col-md-12">
                    <h2>External Transfer </h2>
                    <div className="mt-16">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="bo-tabs-mbl">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={cardHolderDetailsClassName} //""
                                                id="cardHolder-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#cardHolder"
                                                type="button"
                                                role="tab"
                                                aria-controls="cardHolder"
                                                aria-selected={cardHolderDetailsAreaSelected}
                                            >
                                                Add External Account
                                            </button>
                                        </li>

                                    </ul>
                                </div>

                                <div className="tab-content" id="myTabContent">
                                    <div
                                        className={cardHolderContentClassName}
                                        id="cardHolder"
                                        role="tabpanel"
                                        aria-labelledby="cardHolder-tab"
                                    >
                                        <div>
                                            <h3>Enter Account details</h3>
                                            <Form onSubmit={(e) => { e.preventDefault() }} ref={form}>
                                                <div className="row mt-16">
                                                    {/* Customer ID */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_customerId">
                                                            <label>Customer ID*</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="customerId"
                                                                    value={formData.customerId}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors.customerId && (
                                                                <span className="error_meesage">{errors.customerId}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Description */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_description">
                                                            <label>Description*</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="description"
                                                                    value={formData.description}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors.description && (
                                                                <span className="error_meesage">{errors.description}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Counterparty Type */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_counterpartyType">
                                                            <label>Counterparty Type*</label>
                                                            <div className="form-controls">
                                                                <select
                                                                    className="form-control"
                                                                    name="counterpartyType"
                                                                    value={formData.counterpartyType}
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value="">Select Counterparty Type</option>
                                                                    <option value="FIAT_US">FIAT_US</option>
                                                                    <option value="FIAT_EU">FIAT_EU</option>
                                                                </select>
                                                            </div>
                                                            {errors.counterpartyType && (
                                                                <span className="error_meesage">{errors.counterpartyType}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Supported Rails - Multi-Select */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_supportedRails">
                                                            <label>Supported Rails*</label>
                                                            <div className="form-controls">
                                                                {/* Display selected options with remove button */}
                                                                <div className="selected-tags">
                                                                    {formData.supportedRails.map((rail) => (
                                                                        <span key={rail} className="selected-tag">
                                                                            {rail}{" "}
                                                                            <button
                                                                                type="button"
                                                                                className="cross-icon"
                                                                                onClick={() => {
                                                                                    setFormData((prev) => ({
                                                                                        ...prev,
                                                                                        supportedRails: prev.supportedRails.filter((r) => r !== rail),
                                                                                    }));
                                                                                }}
                                                                            >
                                                                                ✕
                                                                            </button>
                                                                        </span>
                                                                    ))}
                                                                </div>

                                                                {/* Multi-select dropdown */}
                                                                <select
                                                                    className="form-control no-icon mt-2"
                                                                    onChange={(e) => {
                                                                        const selectedValue = e.target.value;
                                                                        if (selectedValue && !formData.supportedRails.includes(selectedValue)) {
                                                                            setFormData((prev) => ({
                                                                                ...prev,
                                                                                supportedRails: [...prev.supportedRails, selectedValue],
                                                                            }));
                                                                        }
                                                                    }}
                                                                >
                                                                    <option value="">Select Supported Rail...</option>
                                                                    <option value="ACH">ACH</option>
                                                                    <option value="FEDWIRE">FEDWIRE</option>
                                                                    <option value="SWIFT">SWIFT</option>
                                                                </select>
                                                            </div>

                                                            {errors.supportedRails && (
                                                                <span className="error_meesage">{errors.supportedRails}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Profile Type */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_profileType">
                                                            <label>Profile Type*</label>
                                                            <div className="form-controls">
                                                                <select
                                                                    className="form-control"
                                                                    name="profile.profileType"
                                                                    value={formData.profile.profileType}
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value="">Select Profile Type</option>
                                                                    <option value="INDIVIDUAL">Individual</option>
                                                                    <option value="BUSINESS">Business</option>
                                                                </select>
                                                            </div>
                                                            {errors?.profile?.profileType && (
                                                                <span className="error_meesage">{errors.profile.profileType}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Name */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_name">
                                                            <label>Name*</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="profile.name"
                                                                    value={formData.profile.name}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.profile?.name && (
                                                                <span className="error_meesage">{errors.profile.name}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Email */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_email">
                                                            <label>Email*</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="email"
                                                                    className="form-control no-icon"
                                                                    name="profile.email"
                                                                    value={formData.profile.email}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.profile?.email && (
                                                                <span className="error_meesage">{errors.profile.email}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Telephone Number */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_telephoneNumber">
                                                            <label>Telephone Number*</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="tel"
                                                                    className="form-control no-icon"
                                                                    name="profile.telephoneNumber"
                                                                    value={formData.profile.telephoneNumber}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.profile?.telephoneNumber && (
                                                                <span className="error_meesage">{errors.profile.telephoneNumber}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Tax Reference Number */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_taxReferenceNumber">
                                                            <label>Tax Reference Number*</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="profile.taxReferenceNumber"
                                                                    value={formData.profile.taxReferenceNumber}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.profile?.taxReferenceNumber && (
                                                                <span className="error_meesage">{errors.profile.taxReferenceNumber}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Date of Birth */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_dateOfBirth">
                                                            <label>Date of Birth*</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="date"
                                                                    className="form-control no-icon"
                                                                    name="profile.dateOfBirth"
                                                                    value={formData.profile.dateOfBirth}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.profile?.dateOfBirth && (
                                                                <span className="error_meesage">{errors.profile.dateOfBirth}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Line of Business */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_lineOfBusiness">
                                                            <label>Line of Business*</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="profile.lineOfBusiness"
                                                                    value={formData.profile.lineOfBusiness}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.profile?.lineOfBusiness && (
                                                                <span className="error_meesage">{errors.profile.lineOfBusiness}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Relationship to Customer */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_relationshipToCustomer">
                                                            <label>Relationship to Customer*</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="profile.relationshipToCustomer"
                                                                    value={formData.profile.relationshipToCustomer}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.profile?.relationshipToCustomer && (
                                                                <span className="error_meesage">{errors.profile.relationshipToCustomer}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* Asset Type ID */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_assetTypeId">
                                                            <label>Asset Type ID</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="accountInformation.assetTypeId"
                                                                    value={formData.accountInformation.assetTypeId}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.accountInformation?.assetTypeId && (
                                                                <span className="error_meesage">{errors.accountInformation.assetTypeId}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Account Number */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_accountNumber">
                                                            <label>Account Number</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="accountInformation.accountNumber"
                                                                    value={formData.accountInformation.accountNumber}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.accountInformation?.accountNumber && (
                                                                <span className="error_meesage">{errors.accountInformation.accountNumber}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Account Type */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_accountType">
                                                            <label>Account Type</label>
                                                            <div className="form-controls">
                                                                <select
                                                                    className="form-control"
                                                                    name="accountInformation.type"
                                                                    value={formData.accountInformation.type}
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option value="CHECKING">Checking</option>
                                                                    <option value="SAVING">Saving</option>
                                                                </select>
                                                            </div>
                                                            {errors?.accountInformation?.type && (
                                                                <span className="error_meesage">{errors.accountInformation.type}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Routing Number */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_routingNumber">
                                                            <label>Routing Number</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="accountInformation.routingNumber"
                                                                    value={formData.accountInformation.routingNumber}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.accountInformation?.routingNumber && (
                                                                <span className="error_meesage">{errors.accountInformation.routingNumber}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* SWIFT / BIC Code */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_swiftBic">
                                                            <label>SWIFT / BIC Code</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="accountInformation.swiftBic"
                                                                    value={formData.accountInformation.swiftBic}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.accountInformation?.swiftBic && (
                                                                <span className="error_meesage">{errors.accountInformation.swiftBic}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Institution Name */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_institutionName">
                                                            <label>Institution Name</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="accountInformation.institutionName"
                                                                    value={formData.accountInformation.institutionName}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.accountInformation?.institutionName && (
                                                                <span className="error_meesage">{errors.accountInformation.institutionName}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* Institution Address - Unit Number */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_institutionAddress_unitNumber">
                                                            <label>Institution Address - Unit Number</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="accountInformation.institutionAddress.unitNumber"
                                                                    value={formData.accountInformation.institutionAddress.unitNumber}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.accountInformation?.institutionAddress?.unitNumber && (
                                                                <span className="error_meesage">{errors.accountInformation.institutionAddress.unitNumber}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Institution Address - Address Line 1 */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_institutionAddress_addressLine1">
                                                            <label>Institution Address - Address Line 1</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="accountInformation.institutionAddress.addressLine1"
                                                                    value={formData.accountInformation.institutionAddress.addressLine1}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.accountInformation?.institutionAddress?.addressLine1 && (
                                                                <span className="error_meesage">{errors.accountInformation.institutionAddress.addressLine1}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Institution Address - Address Line 2 */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_institutionAddress_addressLine2">
                                                            <label>Institution Address - Address Line 2</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="accountInformation.institutionAddress.addressLine2"
                                                                    value={formData.accountInformation.institutionAddress.addressLine2}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.accountInformation?.institutionAddress?.addressLine2 && (
                                                                <span className="error_meesage">{errors.accountInformation.institutionAddress.addressLine2}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Institution Address - Address Line 3 */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_institutionAddress_addressLine3">
                                                            <label>Institution Address - Address Line 3</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="accountInformation.institutionAddress.addressLine3"
                                                                    value={formData.accountInformation.institutionAddress.addressLine3}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.accountInformation?.institutionAddress?.addressLine3 && (
                                                                <span className="error_meesage">{errors.accountInformation.institutionAddress.addressLine3}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Institution Address - City */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_institutionAddress_city">
                                                            <label>Institution Address - City</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="accountInformation.institutionAddress.city"
                                                                    value={formData.accountInformation.institutionAddress.city}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.accountInformation?.institutionAddress?.city && (
                                                                <span className="error_meesage">{errors.accountInformation.institutionAddress.city}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Institution Address - State */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_institutionAddress_state">
                                                            <label>Institution Address - State</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="accountInformation.institutionAddress.state"
                                                                    value={formData.accountInformation.institutionAddress.state}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.accountInformation?.institutionAddress?.state && (
                                                                <span className="error_meesage">{errors.accountInformation.institutionAddress.state}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Institution Address - Postal Code */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_institutionAddress_postalCode">
                                                            <label>Institution Address - Postal Code</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="accountInformation.institutionAddress.postalCode"
                                                                    value={formData.accountInformation.institutionAddress.postalCode}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.accountInformation?.institutionAddress?.postalCode && (
                                                                <span className="error_meesage">{errors.accountInformation.institutionAddress.postalCode}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Institution Address - Country Code */}
                                                    <div className="col-md-6">
                                                        <div className="form-group" id="id_institutionAddress_countryCode">
                                                            <label>Institution Address - Country Code</label>
                                                            <div className="form-controls">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control no-icon"
                                                                    name="accountInformation.institutionAddress.countryCode"
                                                                    value={formData.accountInformation.institutionAddress.countryCode}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors?.accountInformation?.institutionAddress?.countryCode && (
                                                                <span className="error_meesage">{errors.accountInformation.institutionAddress.countryCode}</span>
                                                            )}
                                                        </div>
                                                    </div>


                                                    <div className="col-md-12">
                                                        <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                                                            <a
                                                                className="btn btn-outline mr-19"
                                                                data-bs-dismiss="modal"
                                                                onClick={props.onHide}
                                                            >
                                                                Cancel
                                                            </a>
                                                            <a className="btn btn-large" onClick={IssueCardHolderHandler}>
                                                                Create Account
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <CheckButton
                                                    style={{ display: "none" }}
                                                    ref={checkBtn}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                    <div
                                        className={cardContentClassName}
                                        id="cardDetails"
                                        role="tabpanel"
                                        aria-labelledby="cardDetails-tab"
                                    >
                                        <div className="row row-card-details-popup">
                                            <div className="col-md-12">
                                                <ul className="ul-card-details-popup ul-issue-card">
                                                    <li className="mb-20">
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <label>Company Name</label>
                                                            </div>
                                                            <div className="col-8">
                                                                <span>{FirstName}</span>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="mb-20">
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <label>Available Balance</label>
                                                            </div>
                                                            <div className="col-8">
                                                                <span>$ 0</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                                                    <button
                                                        className="btn btn-large"
                                                        data-bs-dismiss="modal"
                                                        onClick={IssueCardDoneHandler}
                                                        disabled={disableBtns}
                                                    >
                                                        Done
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DialoguePopup
                show={modalShow}
                onHide={() => setModalShow(false)}
                modelheading={modelhdng}
                modelbody={modelbdy}
                modelicon={MdlIcon}
                hideallpopups={props.onHide}
            />
        </Fragment>
    );
};
export default AddExtrnalTransferBody;
