import React, { useEffect, useState, useRef, Fragment } from "react";
import cardHolderService from "../../services/cardholder.service";
import DateFormat from "../../helpers/DateFormat";
import ModelPopup from "../../helpers/ModelPopup";
import BoLoader from "../../helpers/BoLoader";
import RefreshToken from "../../helpers/RefreshToken";
import DebitCard from "../cardholders/DebitCard";
import { useDispatch, useSelector } from "react-redux";
import { haspermissions } from "../permissions/PermissionTypes";
import cardholdercardsObj from "../../services/cards.services";
import DialoguePopup from "../../helpers/DialoguePopup";
import ReactFlagsSelect from "react-flags-select";
import { TrimCharacters } from "../../helpers/TrimCharacters";
import { isEmail } from "validator";
import SearchGridBankAccountTransactions from "./SearchGridBankAccountTransactions";
import BankService from "../../services/bank.services";
import './bankaccount.css'
import AddInternalTransferBody from "./AddInternalTransferBody";
import AddExtrnalTransferBody from "./AddExternalTransferBody";
const Emailrequired = value => {
  if (!value) {
    document.getElementById("Email-frmgrp").classList.add("has_error");
    return <span className="help-block">Email is required!</span>;
  } else document.getElementById("Email-frmgrp").classList.remove("has_error");
};


const validEmail = value => {
  if (!isEmail(value)) {
    document.getElementById("Email-frmgrp").classList.add("has_error");
    return <span className="help-block">Invalid Email!</span>;
  } else document.getElementById("Email-frmgrp").classList.remove("has_error");
};

let selectBoxVal = "";
const BankAccountHolderDetails = props => {
 
  const [cardHolderDetail, setCardHolderDetail] = useState();
  const [cardHolderDetailSuccessful, setcardHolderDetailSuccessful] =
    useState(false);
  const [active, setactiveStatus] = useState(true);
  const [statusChangeConfirmBox, setStatusChangeConfirmBox] = useState(false);
  const [boloader, setBoLoader] = useState(false);
  const [userId, setUserId] = useState(props?.location?.state?.userId);
  const [cardDetails, setCardDetails] = useState();
  const [modalShowLoadCard, setmodalShowLoadCard] = useState(false);
  const [modalShowDebitCard, setmodalShowDebitCard] = useState(false);
  const [modalShowKyc, setmodalShowKyc] = useState(false);
  const [TokenId, setTokenId] = useState();
  const [RefereshData, setRefereshData] = useState();
  const { user: currentUser } = useSelector(state => state.auth);
  //Suspend Unsuspend Card
  const [modaldeactivateshow, setModaldeactivateshow] = useState(false);
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const [Status, setStatus] = useState("Unsuspended");
  const form = useRef();
  const checkBtn = useRef();
  //Closedcard
  const [BlockAction, setBlockAction] = useState("");
  //Dialogue Popup
  const [modalShow, setModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [dialougemodalShow, setDialougemodalShow] = useState(false);
  const [dataset, setDataset] = useState();
  //Edit Cardholder Details
  const [modalShowEditCard, setModalShowEditCard] = useState(false);
  const [FirstName, setFirstName] = useState();
  const [LastName, setLastName] = useState();
  const [Address, setAddress] = useState();
  const [City, setCity] = useState();
  const [Country, setCountry] = useState();
  const [ZipCode, setZipCode] = useState();
  const [FirstNameErr, setFirstNameErr] = useState("d-none");
  const [LastNameErr, setLastNameErr] = useState("d-none");
  const [AddressErr, setAddressErr] = useState("d-none");
  const [EmailEdit, setEmailEdit] = useState("");
  //Transactions types
  const [AllTransactionClassName, setAllTransactionClassName] = useState("nav-link active");
  const [AllTransactionAreaSelected, setAllTransactionAreaSelected] = useState("true");
  const [SendMoneyClassName, setSendMoneyClassName] = useState("nav-link");
  const [SendMoneyAreaSelected, setSendMoneyAreaSelected] = useState("false");

  // Change Pin 
  const [newpin, setnewpin] = useState("");
  const [confirmpin, setconfirmpin] = useState("");
  const [kycstatus, setKycstatus] = useState("");
  const [balance, setBalance] = useState("");
  // Account Transfer
  const [modalShowAccountTransfer, setModalShowAccountTransfer] = useState(false);
  const [modalShowInternalAccountTransfer, setModalShowInternalAccountTransfer] = useState(false);
  const [modalShowExternalAccountTransfer, setModalShowExternalAccountTransfer] = useState(false);
  // Benificiary Transfer
  const [modalShowBenificiary, setModalShowBenificiary] = useState(false);
  const onHideModal = () => {
    setnewpin("");
    setconfirmpin("");
  };

  const requiredFirstName = value => {
    if (!value) {
      document.getElementById("edit_firstname").classList.add("has_error");
      setFirstNameErr("");
      return false;
    } else {
      document
        .getElementById("edit_firstname")
        .classList.remove("has_error");
      setFirstNameErr("d-none");
      return true;
    }
  };

  const requiredLastName = value => {
    if (!value) {
      document.getElementById("edit_lastname").classList.add("has_error");
      setLastNameErr("");
      return false;
    } else {
      document
        .getElementById("edit_lastname")
        .classList.remove("has_error");
      setLastNameErr("d-none");
      return true;
    }
  }; const requiredAddress = value => {
    if (!value) {
      document.getElementById("edit_address").classList.add("has_error");
      setAddressErr("");
      return false;
    } else {
      document
        .getElementById("edit_address")
        .classList.remove("has_error");
      setAddressErr("d-none");
      return true;
    }
  };

  if (currentUser) {
    haspermissions.LoadCard =
      currentUser?.user?.Permissions?.includes("Load Card");
    haspermissions.DebitCard =
      currentUser?.user?.Permissions?.includes("Debit Card");
    haspermissions.UpdateCardholderInfo =
      currentUser?.user?.Permissions?.includes("Update Cardholder Info");
    haspermissions.SuspendCard = currentUser?.user?.Permissions?.includes("Suspend Card");

  }

  useEffect(() => {
    setBoLoader(true);
    setcardHolderDetailSuccessful(false);
    callHolderServiceCall();
    setcardHolderDetailSuccessful(false);
  }, [props?.location?.state]);
  const callHolderServiceCall = async () => {
    await cardHolderService
      .GetAccountDetails(props?.location?.state?.id)
      .then(res => {
        if (res && res.data && res.data.success) {
          if (res && res.data && res.data.payload) {
            setCardHolderDetail(res.data.payload?.item1);
            setcardHolderDetailSuccessful(true);

          } else {
            setcardHolderDetailSuccessful(false);
          }
        }
        setBoLoader(false);
      })
      .catch(err => {
        setBoLoader(false);
        (async () => {
          let resp = await RefreshToken(
            err && err.response && err.response.status
          );
          if (resp) {
            window.location.reload();
            setBoLoader(false);
          } else {
            setBoLoader(false);
          }
        })();
        setcardHolderDetailSuccessful(false);
      });
  };
useEffect(()=>{
BankService.checkstatus(props?.location?.state?.id).then((resp)=>{
  
    let msg = JSON.parse(resp?.data?.message);
    setKycstatus(msg?.process_status);
  
}).catch((error)=>{
  try {
    let msgBody = error?.message;
    setBalance(error?.message ?? "");
  } catch (e) {
    console.error("Error parsing error message:", e);
  }
});
},[])

useEffect(()=>{
  BankService.checkaccountbalance(props?.location?.state?.id).then((resp)=>{ 
      let msg = JSON.parse(resp?.data?.message);
      setBalance(msg?.process_status);
  }).catch((error)=>{
    try {
      let msgBody = error?.message;
      setBalance("0");
    } catch (e) {
      console.error("Error parsing error message:", e);
    }
  });
  },[])
  useEffect(()=>{
    BankService.checkaccountbalance(props?.location?.state?.id).then((resp)=>{ 
        let msg = JSON.parse(resp?.data?.message);
        setBalance(msg?.process_status);
    }).catch((error)=>{
      try {
        let msgBody = error?.message;
        setBalance("0");
      } catch (e) {
        console.error("Error parsing error message:", e);
      }
    });
    },[])
    useEffect(()=>{
      BankService.getaccounttransactions(props?.location?.state?.id).then((resp)=>{ 
          let msg = JSON.parse(resp?.data?.message);
          setBalance(msg?.process_status);
      }).catch((error)=>{
        try {
          let msgBody = error?.message;
          setBalance("0");
        } catch (e) {
          console.error("Error parsing error message:", e);
        }
      });
      },[])

  const errorAndSuccessModl = (heading, body, errIcon) => {
    setDialougemodalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
 
  const onEditCardDetails = () => {
    let chckfirstname = requiredFirstName(FirstName);
    let chcklastname = requiredLastName(LastName);
    let chckaddress = requiredAddress(Address);
    if (chckfirstname && chcklastname && chckaddress) {
      setBoLoader(true);
      cardHolderService.onEditCard(FirstName, LastName, Address, City, Country, ZipCode, userId, EmailEdit).then((response) => {
        setBoLoader(false);
        if (response)
          setModalShowEditCard(false);
        let msgBody = response.data.message
        let errIcon = "icon-success";
        errorAndSuccessModl("Success", msgBody, errIcon);
      }).catch((error) => {
        setBoLoader(false);
        let msgBody = error?.response?.data?.message
          ? error?.response?.data?.message

          : "Error Occured";
        let errIcon = "icon-error";
        errorAndSuccessModl("Error", msgBody, errIcon);
      });
    }

  }
  const onChangeFirstName = e => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, '')
    setFirstName(value);
    requiredFirstName(value)
  };
  const onChangeLastName = e => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, '')
    setLastName(value);
    requiredLastName(value);
  };
  const onChangeAddress = e => {
    const name = e.target.value;
    setAddress(name);
    requiredAddress(name);
  };
  const onChangeCity = e => {
    let value = e.target.value;
    value = value.replace(/[^A-Za-z ]/ig, '')
    setCity(value);
  };

  const onChangeZipCode = e => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, '')
    setZipCode(value);
  };

  //Validations Dropdown End
  const onChangeEmail = e => {
    const email = e.target.value;
    setEmailEdit(email);
  };
  const refereshPage = () => {
    setBoLoader(true);
    callHolderServiceCall();
  }
  const onCancelEditCards = () => {
    setFirstName(cardHolderDetail?.firstName);
    setLastName(cardHolderDetail?.lastName);
    setAddress(cardHolderDetail?.address);
    setCity(cardHolderDetail?.city);
    setCountry(cardHolderDetail?.country);
    setZipCode(cardHolderDetail?.zipCode);
    setEmailEdit(cardHolderDetail?.email)
    setLastNameErr("d-none");
    setFirstNameErr("d-none");
    setAddressErr("d-none");
  }

  const transactionTab = () => {
    setAllTransactionClassName("nav-link active d-flex align-items-center");
    setAllTransactionAreaSelected("true");
    setSendMoneyClassName("nav-link d-flex align-items-center");
    setSendMoneyAreaSelected("false");
  }
  const sendMoneyTab = () => {
    setSendMoneyClassName("nav-link d-flex active align-items-center");
    setSendMoneyAreaSelected("true");
    setAllTransactionClassName("nav-link  d-flex align-items-center");
    setAllTransactionAreaSelected("false");

  }

  const InternalAccountPopupHandler=()=>{
    setModalShowInternalAccountTransfer(true);
  }
  const ExtrernalAccountPopupHandler=()=>{
    setModalShowExternalAccountTransfer(true);
  }
  return (
    <div className="col-xl-9 col-lg-12 bo-main-content">
      <BoLoader loading={boloader} />
      {cardHolderDetailSuccessful ? (
        <Fragment>
          <div className="row align-items-center">
            <div className="col-lg-2 mb-24">
              <h3>Bank Account Detail</h3>
            </div>
            <div className="col-lg-10 mb-24">
              <div className="form-group submit-field d-md-flex justify-content-end btns-four">

                
                <button
                  className="btn btn-large btn-extra-large mr-19 mb-3 mb-md-0 res-custom-btn"
                  onClick={InternalAccountPopupHandler}
                >
                   Internal Transfer
                </button>
                <button
                  className="btn btn-large btn-extra-large mr-19 mb-3 mb-md-0 res-custom-btn"
                  onClick={ExtrernalAccountPopupHandler}
                >
                   External Transfer
                </button>
               
              </div>
            </div>

          </div>

          <div className="row-card-holder-details mb-40">
            <div className="row flex-column-reverse flex-sm-row">
              <div className="col-md-12 col-sm-8">
                <ul className="ul-card-details">
                  <li>
                    <div className="mb-20">
                      <p>Client Name</p>
                      <h5>{cardHolderDetail?.clientName}</h5>
                    </div>

                    <div className="mb-20">
                      <p>Email</p>
                      <h5 className="text-break"> {cardHolderDetail?.email}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Currency</p>
                      <h5>
                        $
                      </h5>
                    </div>
                    <div className="mb-20">
                      <p>Date of Birth</p>
                      <h5>
                        <DateFormat date={cardHolderDetail?.dateOfBirth} />
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="mb-20">
                      <p>Account Title</p>
                      <h5> {cardHolderDetail?.firstName+ ` `+cardHolderDetail?.lastName}</h5>
                    </div>
                    <div className="mb-20">
                      <p>City</p>
                      <h5> {cardHolderDetail?.city}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Country</p>
                      <h5> {cardHolderDetail?.country}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Zip Code</p>
                      <h5> {cardHolderDetail?.zipCode}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Balance</p>
                      <h5> {balance}</h5>
                    </div>
                  </li>
                  <li>
                  <div className="mb-20">
                      <p>Account Number ACH</p>
                      <h5> {cardHolderDetail?.accountNumberAch}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Account Number FED</p>
                      <h5> {cardHolderDetail?.accountNumberFed}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Routing Number ACH</p>
                      <h5> {cardHolderDetail?.routingNumberAch}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Routing Nunber FED</p>
                      <h5> {cardHolderDetail?.routingNumberFed}</h5>
                    </div>
                  </li>
                 <li>
                 <div className="mb-20">
                      <p>Token Id</p>
                      <h5> {cardHolderDetail?.tokenId}</h5>
                    </div>
                    <div className="mb-20">
                      <p>KYC Status</p>
                      <h5> {kycstatus}</h5>
                    </div>
                 </li>
                </ul>
              </div>
             
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div className="bo-tabs-mbl">
                <ul className="nav nav-tabs" id="myTab2" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className={AllTransactionClassName}
                      id="allClients-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#allClients"
                      type="button"
                      role="tab"
                      onClick={transactionTab}
                      aria-controls="allClients"
                      aria-selected={AllTransactionAreaSelected}
                    >
                      <span ></span> Tranasactions
                    </button>

                  </li>
                 
                </ul>
              </div>
            </div>

          </div>
          {
            AllTransactionAreaSelected === "true" && (
              <SearchGridBankAccountTransactions
                placeholdervalue="Search Card Holder Transactions"
                headersdata={[
                  "ICON",
                  "TRANSACTION TYPE",
                  "CURRENCY",
                  "AMOUNT",
                  "DATE",
                  "STATUS",
                ]}
                screenname="cardholdertransactions"
                UserId={props?.location?.state?.id}
                issuerCategory={props?.location?.state?.issuerCategory}
                setDataset={setDataset}
                calledfrom="accounttransaction"
              />
            )
          }
          {
            SendMoneyAreaSelected === "true" && (
              <SearchGridBankAccountTransactions
                placeholdervalue="Search Card Holder Transactions"
                headersdata={[
                  "Value",
                "TransactionDate",
                "TransactionStatus",
                "TransactionType",
                "Description"
                ]}
                screenname="cardholdertransactions"
                UserId={props?.location?.state?.id}
                issuerCategory={props?.location?.state?.issuerCategory}
                setDataset={setDataset}
                calledfrom="benificiarylist"
              />
            )
          }

          {TokenId && (
            <DebitCard
              show={modalShowDebitCard}
              onHide={() => setmodalShowDebitCard(false)}
              calledfrom="cardholderdetails"
              TokenID={TokenId}
              CurrencyCode={cardDetails?.cardFeeCurrencyCode}
              Balance={cardHolderDetail?.availableBalance}
              paymentProcessor={cardDetails?.paymentProcessor}
              clientId={cardHolderDetail?.clientId}
              issuerCategory={cardHolderDetail?.issuerCategory}
              userId={userId}
              companyname={cardHolderDetail?.companyName}
            />
          )}

          <ModelPopup
            show={modalShowKyc}
            key={12}
            onHide={() => setmodalShowKyc(false)}
            modelheading={""}
            showheader={"false"}
            className="smallPopup issueCardSmall"
            dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
            modelbody={[
              <Fragment>
                <form onSubmit={e => e.preventDefault()}>
                  <div className="row">
                    <div className="col-md-12">
                      <h2>KYC Card Detail</h2>
                      <ul className="ul-card-details-popup mt-16 ul-issue-card">
                        <li className="mb-16">
                          <div className="row">
                            <div className="col-4">
                              <label>Name</label>
                            </div>
                            <div className="col-8">
                              <span>{cardHolderDetail?.cardHolderName}</span>
                            </div>
                          </div>
                        </li>
                        <li className="mb-16">
                          <div className="row">
                            <div className="col-4">
                              <label>Token ID</label>
                            </div>
                            <div className="col-8">
                              <span>{cardDetails?.tokenId}</span>
                            </div>
                          </div>
                        </li>
                        <li className="mb-16">
                          <div className="row">
                            <div className="col-4">
                              <label>Company Name</label>
                            </div>
                            <div className="col-8">
                              <span>{cardHolderDetail?.companyName}</span>
                            </div>
                          </div>
                        </li>
                        <li className="mb-16">
                          <div className="row">
                            <div className="col-4">
                              <label>Card Expiry Date</label>
                            </div>
                            <div className="col-8">
                              <span>
                                <DateFormat date={cardDetails?.expiryDate} />
                              </span>
                            </div>
                          </div>
                        </li>
                        <li className="mb-32">
                          <div className="row">
                            <div className="col-4">
                              <label>Status</label>
                            </div>
                            <div className="col-8">

                              <span>Approved</span>
                            </div>
                          </div>
                        </li>
                        {
                          cardHolderDetail?.idType && (
                            <Fragment>
                              <li className="mb-24">
                                <div className="row">
                                  <div className="col-4">
                                    <label>ID Type</label>
                                  </div>
                                  <div className="col-8">
                                    <span>{cardHolderDetail?.idType}</span>
                                  </div>
                                </div>
                              </li>
                              <li className="mb-40">
                                <div className="text-center id-doc">
                                  <a
                                    href={cardHolderDetail?.filePathIdType}
                                    id="downloadidtype"
                                  >
                                    <img
                                      src={cardHolderDetail?.filePathIdType}
                                      alt="id document"
                                    />
                                  </a>
                                  <div>
                                    <a
                                      href={cardHolderDetail?.filePathIdType}
                                      style={{ textDecoration: `underline`, color: `blue` }}>
                                      Download image
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </Fragment>)
                        }
                        {
                          cardHolderDetail?.addressProof && (
                            <Fragment>
                              <li className="mb-24">
                                <div className="row">
                                  <div className="col-4">
                                    <label>Proof of Address</label>
                                  </div>
                                  <div className="col-8">
                                    <span>{cardHolderDetail?.addressProof}</span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="text-center add-paper">
                                  <a
                                    href={cardHolderDetail?.filePathAddressProof}
                                    id="downloadaddrproof"
                                  >
                                    <img
                                      src={cardHolderDetail?.filePathAddressProof}
                                      alt="house dodument"
                                    />
                                  </a>
                                  <div>
                                    <a href={cardHolderDetail?.filePathAddressProof}
                                      style={{ textDecoration: `underline`, color: `blue` }}>
                                      Download image
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </Fragment>)}


                      </ul>
                      <div className="form-group submit-field d-flex justify-content-end mt-30 mb-0">
                        <button
                          className="btn btn-large"
                          onClick={() => {
                            setmodalShowKyc(false);
                            //setShowAccptCnfrmPopup(true);
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                {/* {noDataFound && <p>No details found for selected card holder</p>} */}
              </Fragment>,
            ]}
          />
        
          <ModelPopup
            show={modalShowEditCard}
            key={15}
            onHide={() => { setModalShowEditCard(false); onCancelEditCards(); }}
            modelheading={""}
            showheader={"false"}
            className="smallPopup issueCardSmall"
            dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
            backdrop="static"
            keyboard={false}
            modelbody={[
              <Fragment>
                <form onSubmit={e => e.preventDefault()}>
                  <h2>Edit CardHolder Details</h2>
                  <div className="row mt-16">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group" id="edit_firstname">
                            <label>First Name*</label>
                            <div className="form-controls">
                              <input
                                type="text"
                                className="form-control  no-icon"
                                name="cardholderName"
                                value={FirstName}
                                onChange={onChangeFirstName}
                                onKeyDown={(evt) => {
                                  let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                                  if (chck)
                                    evt.preventDefault();
                                }
                                }
                                // validations={[firstNameRequired]}
                                maxLength="20"
                              />

                            </div>
                            <span
                              className={
                                "help-block " + FirstNameErr
                              }
                            >
                              First Name is required!
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group" id="edit_lastname">
                            <label>Last Name*</label>
                            <div className="form-controls">
                              <input
                                type="text"
                                className="form-control  no-icon"
                                name="cardholderName"
                                value={LastName}
                                onChange={onChangeLastName}
                                onKeyDown={(evt) => {
                                  let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                                  if (chck)
                                    evt.preventDefault();
                                }
                                }
                                maxLength="20"
                              />

                            </div>
                            <span
                              className={
                                "help-block " + LastNameErr
                              }
                            >
                              Last Name is required!
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group" id="edit_address">
                            <label>Address*</label>
                            <div className="form-controls">
                              <input
                                type="text"
                                className="form-control  no-icon"
                                name="cardholderName"
                                value={Address}
                                onChange={onChangeAddress}
                                maxLength="40"
                              />

                            </div>
                            <span
                              className={
                                "help-block " + AddressErr
                              }
                            >
                              Address is required!
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group" id="edit_city">
                            <label>City</label>
                            <div className="form-controls">
                              <input
                                type="text"
                                className="form-control  no-icon"
                                name="cardholderName"
                                value={City}
                                onChange={onChangeCity}
                                onKeyDown={(evt) => {
                                  let chck = TrimCharacters.checkAlpha(evt.key)
                                  if (chck)
                                    evt.preventDefault();
                                }
                                }
                                maxLength="20"
                              />

                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group" id="id_deliverycountry">
                            <label>Country*</label>
                            <div className="form-controls">
                              <ReactFlagsSelect
                                className="form-control no-icon"
                                selected={Country}
                                onSelect={code => {
                                  setCountry(code);
                                }}
                                searchable={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group" id="edit_zipcode">
                            <label>Zip Code</label>
                            <div className="form-controls">
                              <input
                                type="text"
                                className="form-control  no-icon"
                                name="cardholderName"
                                value={ZipCode}
                                onChange={onChangeZipCode}
                                onKeyDown={(evt) => {
                                  let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                                  if (chck)
                                    evt.preventDefault();
                                }
                                }
                                maxLength="10"
                              />

                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group" id="edit_email">
                            <label>Email</label>

                            <div className="form-controls">
                              <input
                                type="text"
                                className="form-control  no-icon"
                                name="email"
                                value={EmailEdit}
                                onChange={onChangeEmail}
                                validations={[Emailrequired, validEmail]}
                                // onKeyDown={(evt) => {
                                //   let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                                //   if (chck)
                                //     evt.preventDefault();
                                // }
                                // }
                                maxLength="60"
                              />

                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                          <a
                            className="btn btn-outline mr-19"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              setModalShowEditCard(false);
                              onCancelEditCards();
                            }}
                          >
                            Cancel
                          </a>
                          <a
                            className="btn btn-large"
                            onClick={onEditCardDetails}
                          >
                            Save
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                {/* {noDataFound && <p>No details found for selected card holder</p>} */}
              </Fragment>,
            ]}
          />
    <ModelPopup
      show={modalShowInternalAccountTransfer}
      onHide={()=>{setModalShowInternalAccountTransfer(false)}}
      modelheading={""}
      showheader={"false"}
      className="smallPopup issueCardSmall"
      dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
      backdrop="static"
      keyboard={false}
      modelbody={<AddInternalTransferBody show={modalShowInternalAccountTransfer} onHide={()=>{setModalShowInternalAccountTransfer(false)}} />}
    />
     <ModelPopup
      show={modalShowExternalAccountTransfer}
      onHide={()=>{setModalShowExternalAccountTransfer(false)}}
      modelheading={""}
      showheader={"false"}
      className="smallPopup issueCardSmall"
      dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
      backdrop="static"
      keyboard={false}
      modelbody={<AddExtrnalTransferBody show={modalShowExternalAccountTransfer} onHide={()=>{setModalShowExternalAccountTransfer(false)}} />}
    />
        
          <DialoguePopup
            show={dialougemodalShow}
            onHide={() => setDialougemodalShow(false)}
            hideallpopups={() => { setDialougemodalShow(false); refereshPage(); }}
            modelheading={modelhdng}
            modelbody={modelbdy}
            modelicon={MdlIcon}
          />
        </Fragment>
      ) : (<div><h5></h5></div>)}
    </div>
  );
};

export default BankAccountHolderDetails;
