import axios from "axios";
import { Env } from "../enviroment/environment";
import authHeader from "./auth-header";
const API_URL_BANKACCOUNT = Env.BankAccountApiUrl;


const createbankaccount = (
    formData
) => {
  return axios
    .post(
        API_URL_BANKACCOUNT + "createbankaccount",
        formData
      ,
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
      }

      return response;
    });
};
const checkstatus = (userId) => {
  return axios
    .post(
      API_URL_BANKACCOUNT + "checkstatus?userId=" + userId,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res;
    });
};
const checkaccountbalance = (userId) => {
  return axios
    .post(
      API_URL_BANKACCOUNT + "checkaccountbalance?userId=" + userId,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res;
    });
};
const getaccounttransactions = (userId) => {
  return axios
    .post(
      API_URL_BANKACCOUNT + "getaccounttransactions?userId=" + userId,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res;
    });
};
const createtransferrequest = (
  formData
) => {
return axios
  .post(
      API_URL_BANKACCOUNT + "createtransferrequest",
      formData
    ,
    { headers: authHeader() }
  )
  .then((response) => {
    if (response.data.success) {
    }

    return response;
  });
};
const createcounterparty = (
  formData
) => {
return axios
  .post(
      API_URL_BANKACCOUNT + "createcounterparty",
      formData
    ,
    { headers: authHeader() }
  )
  .then((response) => {
    if (response.data.success) {
    }

    return response;
  });
};
const BankService = {
    createbankaccount,
    checkstatus,
    checkaccountbalance,
    getaccounttransactions,
    createtransferrequest,
    createcounterparty
 
};
export default BankService;
