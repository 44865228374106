

import DialoguePopup from "../../helpers/DialoguePopup";
import React, { useState, useRef, useEffect, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import BankService from "../../services/bank.services";
import BoLoader from "../../helpers/BoLoader";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import RefreshToken from "../../helpers/RefreshToken";
const AddInternalTransferBody = props => {
  const { user: currentUser } = useSelector(state => state.auth);

  const [cardHolderDetailsAreaSelected, setCardHolderDetailsAreaSelected] =
    useState("true");
    useState("false");
  const [cardHolderDetailsClassName, setTcardHolderDetailsClassName] =
    useState("nav-link active");
  const [cardHolderContentClassName, setCardHolderContentClassName] = useState(
    "tab-pane fade show active"
  );
  const [modalShow, setModalShow] = React.useState(false);
  const form = useRef();
  const checkBtn = useRef();
  const [modelhdng, setMdlHdng] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [boloader, setBoLoader] = useState(false);
  const [ClientNameVal, setClientNameVal] = useState("");
  const [errors, setErrors] = useState({}); // Error state

  //Issuer S or ISsuer B For m-Swipe
  const [IssuerCategory, setIssuerCategory] = useState();
  const [CardFlow, setCardFlow] = useState();
  const [CheckcardVisaMaster, setCheckCardVisaMaster] = useState("Select");
  const [formData, setFormData] = useState({
    SourceTokenId: '',
    DestinationTokenId: '',
    amount: 0,
    description: ''
  });
  // Custom validation rules
  const validateField = (name, value) => {
    let errorMsg = "";

    // Ensure value is a string before using trim()
    const strValue = value !== undefined && value !== null ? String(value).trim() : "";

    if (!strValue) {
      errorMsg = `${name} is required`;
    } else {
      if (name === "email" && !/^\S+@\S+\.\S+$/.test(strValue)) {
        errorMsg = "Invalid email format";
      }
      if (name === "phone" && !/^\d{10,15}$/.test(strValue)) {
        errorMsg = "Invalid phone number (must be 10-15 digits)";
      }
    }

    return errorMsg;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate field
    const errorMsg = validateField(name, value);

    // Update state
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Update errors
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));
  };
  //API Call Start
  const IssueCardHolderHandler = e => {
    if (e) e.preventDefault();
    form.current.validateAll();
    formData.amount = Number(formData.amount);
    let newErrors = {};
    Object.keys(formData).forEach((field) => {
      const errorMsg = validateField(field, formData[field]);
      if (errorMsg) newErrors[field] = errorMsg;
    });
    // If errors exist, update state and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
   
      setBoLoader(true);
      BankService.createtransferrequest
        (formData,
        )
        .then(bankData => {
          setBoLoader(false);
          if (bankData && bankData.data && bankData.data.success) {
            setCardHolderDetailsAreaSelected("false");
            setTcardHolderDetailsClassName("nav-link");
            setCardHolderContentClassName("tab-pane fade");
          }
        })
        .catch(err => {
          setBoLoader(false);
          let msgBody =
            err && err.response && err.response.data
              ? err.response.data.message
              : "Error Occured While Creating Account";
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
          (async () => {
            setBoLoader(true);
            let resp = await RefreshToken(
              err && err.response && err.response.status
            );
            if (resp) {
              IssueCardHolderHandler();
              setBoLoader(false);
            } else {
              setBoLoader(false);
            }
          })();
        });
    

  };
  //API Call End
  const errorAndSuccessModl = (heading, body, Icon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(Icon);
  };

  const IssueCardDoneHandler = e => {
    e.preventDefault();
    // setDisabledBtns(true);
    props.onHide();
  };

  return (
    <Fragment>
      <div className="row">
        <BoLoader loading={boloader} />
        <div className="col-md-12">
          <h2>Internal Transfer </h2>
          <div className="mt-16">
            <div className="row">
              <div className="col-md-12">
                <div className="bo-tabs-mbl">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={cardHolderDetailsClassName} //""
                        id="cardHolder-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#cardHolder"
                        type="button"
                        role="tab"
                        aria-controls="cardHolder"
                        aria-selected={cardHolderDetailsAreaSelected}
                      >
                         Details
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="tab-content" id="myTabContent">
                  <div
                    className={cardHolderContentClassName}
                    id="cardHolder"
                    role="tabpanel"
                    aria-labelledby="cardHolder-tab"
                  >
                    <div>
                      <h3>Enter Account details</h3>
                      <Form onSubmit={(e) => { e.preventDefault() }} ref={form}>
                        <div className="row mt-16">
                          
                          <div className="col-md-6">
                            <div className="form-group" id="id_sourcetokenid">
                              <label>Souce Token Id*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="SourceTokenId"
                                  value={formData.SourceTokenId}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.firstName && (
                                <span className="error_meesage">{errors.SourceTokenId}</span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="id_DestinationTokenId">
                              <label>Destination Token Id*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="DestinationTokenId"
                                  value={formData.DestinationTokenId}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.lastName && (
                                <span className="error_meesage">{errors.DestinationTokenId}</span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="id_amount">
                              <label>Amount*</label>
                              <div className="form-controls">
                                <Input
                                  type="number"
                                  className="form-control no-icon"
                                  name="amount"
                                  value={formData.amount}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.mailingAddressNo && (
                                <span className="error_meesage">{errors.amount}</span>
                              )}

                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="id_description">
                              <label>Description*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="description"
                                  value={formData.description}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.mailingAddressLine1 && (
                                <span className="error_meesage">{errors.description}</span>
                              )}
                            </div>
                          </div>
                          
                          <div className="col-md-12">
                            <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                              <a
                                className="btn btn-outline mr-19"
                                data-bs-dismiss="modal"
                                onClick={props.onHide}
                              >
                                Cancel
                              </a>
                              <a className="btn btn-large" onClick={IssueCardHolderHandler}>
                                Tranfer
                              </a>
                            </div>
                          </div>
                        </div>
                        <CheckButton
                          style={{ display: "none" }}
                          ref={checkBtn}
                        />
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
        hideallpopups={props.onHide}
      />
    </Fragment>
  );
};
export default AddInternalTransferBody;
